import MDInput from '../components/MDInput';
import StoreLeadService from '../services/StoreLeadService';

export default {
  init() {
    // JavaScript to be fired on the home page
    MDInput.create();
    StoreLeadService.create();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
