/**
 * Maneja Material Design Input
 */
class MDInput {
    constructor() {
        this.el = $('.md-form input')
        this.elLabel = $('.md-form label')
        this.mount()
    }

    static create() {
        return new this()
    }

    mount() {
        this.listeners()
    }

    /**
    * Agrega escuchadores de eventos
    */
    listeners() {
        this.onClick()
        this.onInput()
        this.onFocus()
        this.onBlur()
    }

    /**
    * Maneja evento para click en input o su label
    */
    onClick() {
        const elements = [this.el, this.elLabel]

        const selectors = $.map(elements, function (el) { return el.get() })

        $(selectors).on('click', function () {
            if ($(this).is('label')) {
                $(this).prev('input').trigger('click')
                return
            }
            $(this).next('label').addClass('active')
            $(this).trigger('focus')
        })
    }

    /**
    * Maneja evento cuando input es actualizado
    */
    onInput() {
        this.el.on('input', function () {
            $(this).next('label').addClass('active')
        })
    }

    /**
    * Maneja evento cuando input es seleccionado
    */
    onFocus() {
        this.el.on('focus', function () {
            $(this).next('label').addClass('active')
        })
    }


    /**
    * Maneja evento cuando input es deseleccionado
    */
    onBlur() {
        this.el.on('blur', function () {
            if ($(this).val()) return
            if ($(this).next('label').hasClass('active-default')) {
                return
            }
            $(this).next('label').removeClass('active')
        })
    }
}

export default MDInput