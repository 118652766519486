class StoreLeadService {
    static create() {
        return new this();
    }

    constructor() {
        this.beforeMount();
        this.mount();
    }

    beforeMount() {
        this.setDefaultDates();
    }

    mount() {
        (function ($) {
            let service = {
                url: '/wp-admin/admin-ajax.php',
                action: 'new_lead',
                form: {
                    name: $('#form_new_lead input[name="name"]'),
                    email: $('#form_new_lead input[name="email"]'),
                    adults: $('#form_new_lead input[name="adults"]'),
                    date_start: $('#form_new_lead input[name="date_start"]'),
                    phone: $('#form_new_lead input[name="phone"]'),
                    experiences: $('#form_new_lead input[name="experiences"]'),
                },

                init: function () {
                    $('#form_new_lead').on('submit', function (e) {
                        e.preventDefault();
                        service.disableSubmit(true);
                        service.loading.show();
                        service.clearErrors();
                        service.send();
                    });
                },

                loading: {
                    spinner: $('#btn_new_lead > span.spinner-border'),
                    show: function () {
                        this.spinner.show();
                    },
                    hide: function () {
                        this.spinner.hide();
                    },
                },

                disableSubmit: function (isDisabled = false) {
                    if (isDisabled) {
                        $('#btn_new_lead').prop('disabled', true);
                        return;
                    }
                    $('#btn_new_lead').prop('disabled', false);
                },

                clearErrors: function () {
                    $('#form_new_lead input').removeClass('is-invalid');
                },

                reset: function () {
                    $('#form_new_lead input:not([type="date"])').val('');
                },

                success: function () {
                    $('#success_new_lead').fadeIn();

                    setTimeout(() => {
                        $('#success_new_lead').fadeOut();
                    }, 3000);
                },

                send: function () {
                    $.post(service.url, {
                        action: service.action,
                        data: {
                            name: service.form.name.val(),
                            email: service.form.email.val(),
                            adults: service.form.adults.val(),
                            date_start: service.form.date_start.val(),
                            phone: service.form.phone.val(),
                            experiences: service.form.experiences.val(),
                        },
                    }).done(service.done);
                },

                done: function (response) {
                    service.disableSubmit(false);
                    service.loading.hide();

                    response.success === false
                        ? service.errors(response.data)
                        : service.result(response.data);
                },

                errors: function (fields) {
                    Object.keys(fields).forEach((key) => {
                        $('#form_new_lead [name="' + key + '"]').addClass('is-invalid');
                        $('#form_new_lead [name="' + key + '"] ~ .invalid-feedback').html(
                            fields[key]
                        );
                    });
                },

                result: function () {
                    service.reset();
                    service.success();
                },
            };

            service.init();
        })(jQuery);
    }

    setDefaultDates() {
        const date_start = $('#form_new_lead input[name="date_start"]');
        /* const date_end = $('#form_new_lead input[name="date_end"]'); */

        const today = new Date();
        const start = today.setDate(today.getDate() + 10); // SUMA 10 DIAS
        /* const end = today.setDate(today.getDate() + 20); // SUMA 20 DIAS */

        date_start.val(new Date(start).toISOString().substring(0, 10));
        /* date_end.val(new Date(end).toISOString().substring(0, 10)); */
    }
}

export default StoreLeadService;
